@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    font-family: "Figtree", sans-serif;
  }

  @layer utilities {
    .custom-bg-class {
      background-image: url('background.svg');
      /* Additional background properties and styles */
    }
  }


/* Nav.css */

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 1rem;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    justify-content: space-between;
    background-color: #FDFDFD; 
    transition: transform 0.3s ease-in-out;
  }
  
  .nav-hidden {
    transform: translateY(-100%);
  }
  
  .nav-visible {
    transform: translateY(0);
  }

  .text-behance {
    color: #0E0E0E; 
  }
  
  .text-linkedin {
    color: #0077b5; 
  }
 
  /* Hero.css */

.hero-container {
    display: flex;
  flex-direction: column;
    /* background-color: #fafafa; Adjust the background color as needed */
    padding: 0rem;
  }
  
  .text-container {
    text-align: left;
    padding: 2rem;
  }
  
  .text-container h1 {
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  }
  
  .image-container {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
  }
  /* Tech.css */

.tech-name {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.relative:hover .tech-name {
  opacity: 1;
}


@keyframes blink-caret {
  from, to {
    border-color: transparent; /* Blinking cursor - hide on from and to */
  }
  50% {
    border-color: orange; /* Blinking cursor - show on 50% */
  }
}


  
  @media (min-width: 768px) {
    .hero-container {
      flex-direction: row;
      height: 70vh; /* Adjust the height as needed */
    }
  
    .text-container {
      flex: 1;
      padding: 4rem;
    }
  
    .image-container {
      flex: 1;
      height: 100%;
    }
  }
  
  